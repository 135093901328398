import { useEffect, useState } from "react"
import { GetGeoJSONFlows } from "../cibic_actions"
//import testData from './data/web_viz.json';



export const useRides = (region_id)=>{
	const [rides, setRides] = useState({})
	
	
	useEffect(()=>{
		GetGeoJSONFlows(region_id).then((data)=>{
			let lines = [] 
			for(const ride of data){
				let lineFeature = ride.features.find(e => e.geometry.type === "LineString") 
				if (lineFeature.geometry.coordinates){
					lineFeature['properties'] = ride.properties
					lines.push(lineFeature)
				}
			}
			let collection = {
				'type': 'FeatureCollection',
				'features': lines
			}
			setRides(collection)
		})/*.catch((err) => {
      let lines = [] 
      for(const ride of testData){
				let lineFeature = ride.features.find(e => e.geometry.type === "LineString") 
				if (lineFeature.geometry.coordinates){
					lineFeature['properties'] = ride.properties
					lines.push(lineFeature)
				}
			}
			let collection = {
				'type': 'FeatureCollection',
				'features': lines
			}
			setRides(collection)
    })
      */
	}, [region_id])

	return rides
}