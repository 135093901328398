import React, { useEffect, useState } from 'react';
import { GetEventList } from '../utils/cibic_actions';

export const EventContext = React.createContext({});

const EventProvider = ({ children }) => {
  const [selectedEventId, setSelectedEventId] = useState("0")
  const [currentEvents, setCurrentEvents] = useState([])

  useEffect(() => {
    GetEventList().then((data) => {
      console.log(data['events'])
      const BLACKLIST = ['321076', '305885', '318276', '301969', '321136']
      setCurrentEvents(data['events'].filter(e => !BLACKLIST.includes(e.id) ))
    }, (err) => {
      console.log(err)
    });
  }, [])

  const UpdateSelectedEventWithID = (id) => {
    setSelectedEventId(id)
  }

  const EventData = {
    event_id: selectedEventId,
    event_list: currentEvents,
    UpdateSelectedEventWithID,
  }
  


  return (
    <EventContext.Provider value={EventData}>
      {children}
    </EventContext.Provider>
  )

}

export default EventProvider