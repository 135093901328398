import React, { useState, useContext, useEffect } from 'react';
import './EventSelector.css';

import translations from '../../assets/translations.json'

import { EventContext } from '../../app/eventContext';
import { RegionContext } from '../../app/regionContext';

function EventSelector() {
  const { event_id, event_list, UpdateSelectedEventWithID } = useContext(EventContext)
  const { lang } = useContext(RegionContext)
  const [selectedEvent, setSelectedEvent] = useState(event_id)

  const handleSelection = (e) => {
    const eventID = e.target.value
    console.log(`===== EventSelector:handleSelection ${eventID} =====`)
    UpdateSelectedEventWithID(eventID)
    setSelectedEvent(eventID)
  }

  useEffect(() => {
    setSelectedEvent(event_id)
  }, [event_id])

  useEffect(() => {
    console.log('===== EventSelector:useEffect =====')
    console.log(JSON.stringify(event_list))
  }, [event_list])

  return (
    <select name="event-selector" id="event-selector" value={selectedEvent} onChange={handleSelection}>
      <option value="0" key="0">{translations.header.eventSelectorDefault[lang]}</option>
      {event_list.map((event_item, i) => (<option value={event_item['id']} key={i+1}>{event_item['name']}</option>))}
    </select>
  );

}

export default EventSelector