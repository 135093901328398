import React, { useContext } from 'react';
import './Header.css';
import About from '../About';
import Translator from '../Translator';
import globe from '../../assets/globe.svg';
import help from '../../assets/help.svg'
import translations from '../../assets/translations.json'
import EventSelector from '../EventSelector';
import DateSelector from '../DateSelector';

import { DateContext } from '../../app/dateContext';
import { RegionContext } from '../../app/regionContext';

const Header = () => {
  const { min_date, max_date, UpdateStartDate, UpdateEndDate } = useContext(DateContext)
  const { lang } = useContext(RegionContext)

  function handleAbout() {
    let aboutPopup = document.getElementById('about');
    aboutPopup.classList.toggle('hidden');
  }

  function handleTranslator() {
    let languageSelector = document.getElementById('language-selector');
    languageSelector.classList.toggle('hidden');
  }

  return (
    <header>
      <div className="header-row">
        <h1 className="title">{translations.mainTitle[lang]}</h1>
        <img className="icon" src={globe} alt="Language Translate" onClick={handleTranslator} />
      </div>
      <div className="header-row">
        <EventSelector />
        <div className="instructionContainer">
          <div className="instructionText">{translations.header.dateFilterInstruction[lang]}:</div>
        </div>
        <div className="dateSelectorsContainer">
          <DateSelector initDate={min_date} minDate={min_date} maxDate={max_date} onDateSelected={UpdateStartDate} />
          <DateSelector initDate={max_date} minDate={min_date} maxDate={max_date} onDateSelected={UpdateEndDate} />
        </div>
        <img className="icon" src={help} alt="More Information" onClick={handleAbout} />
      </div>
      <About />
      <Translator />
    </header>
  );
}

export default Header;
