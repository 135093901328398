import React, { useState } from 'react';
import dayjs from 'dayjs';

export const DateContext = React.createContext({});

const DateProvider = ({ children }) => {
  const MAX_DATE = dayjs()
  const MIN_DATE = MAX_DATE.subtract(90, 'day')

  const [selectedStartDate, setSelectedStartDate] = useState(MIN_DATE)
  const [selectedEndDate, setSelectedEndDate] = useState(MAX_DATE)

  const UpdateStartDate = (startDate) => {
    console.log(`===== UpdateStartDate =====`)
    setSelectedStartDate(startDate)
  }

  const UpdateEndDate = (endDate) => {
    console.log(`===== UpdateEndDate =====`)
    setSelectedEndDate(endDate)
  }

  const DateData = {
    start_date: selectedStartDate,
    end_date: selectedEndDate,
    min_date: MIN_DATE,
    max_date: MAX_DATE,
    UpdateStartDate,
    UpdateEndDate
  }
  


  return (
    <DateContext.Provider value={DateData}>
      {children}
    </DateContext.Provider>
  )

}

export default DateProvider