import React  from 'react';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00a752',
      light: '#00a752',
      dark: '#00a752',
    },
    text: {
      secondary: '#00a752',
      icon: '#00a752',
    },
  },
})

const CustomMobileDatePicker = styled(MobileDatePicker)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    height: '35px',
    color: 'rgba(245,245,245,0.9)',
    fontFamily: 'Space Mono',
    fontSize: '1em',
    fontWeight: '400'
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    border: '1px solid rgba(245,245,245,0.9)',
    borderRadius: '0px'
  },
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    border: '2px solid rgba(245,245,245,0.9)',
    borderRadius: '0px'
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '3px solid rgba(245,245,245,0.9)',
    borderRadius: '0px'
  },
}))

//.css-1wy8uaa-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover
function DateSelector({ initDate, minDate, maxDate, onDateSelected }) {
  return (
    <ThemeProvider theme={theme}>
      <CustomMobileDatePicker
        defaultValue={initDate}
        minDate={minDate}
        maxDate={maxDate}
        onAccept={onDateSelected}
      />
    </ThemeProvider>
  );
}

export default DateSelector