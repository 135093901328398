import React, {useContext} from 'react';
import './Switcher.css';

import classNames from 'classnames';

import map_on from '../../assets/map-on.svg';
import map_off from '../../assets/map-off.svg';
import gallery_on from '../../assets/photo-library-on.svg';
import gallery_off from '../../assets/photo-library-off.svg';
import translations from '../../assets/translations.json'

import { RegionContext } from '../../app/regionContext';

import { useState } from 'react';


function Switcher({OnChange}) {
  const [Selected, setSelected] = useState(0)
  const {lang} = useContext(RegionContext)

  const handleChange = (index)=>{
    console.log("switching to ", index)
    setSelected(index)
    OnChange(index)
  }


  return (
      <div id="switcher">
        <div id="mapDiv" title={translations.footer.mapTitle[lang]} className={classNames("switch-view", {"selected": Selected===0})} onClick={()=>{handleChange(0)}}>
          {Selected===0?(
            <img id="mapImgOn" className="icon icon-map switch-map" src={map_on} alt="Map View - On" />
            ):(
              <img id="mapImgOff" className="icon icon-map switch-map" src={map_off} alt="Map View - Off" />
            )}
        </div>
        <div id="galleryDiv" title={translations.footer.galleryTitle[lang]} className={classNames("switch-view", {"selected": Selected===2})} onClick={()=>{handleChange(2)}}>
          {Selected===2?(
              <img id="galleryImgOn" className="icon" src={gallery_on} alt="Gallery View - On" />
            ):(
              <img id="galleryImgOff" className="icon" src={gallery_off} alt="Gallery View - Off"/>
            )}
        </div>
      </div>
  );
}

export default Switcher;
